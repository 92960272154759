import * as Analytics from '@iheartradio/web.analytics';
import { isNotBlank } from '@iheartradio/web.utilities';
import { useEffect } from 'react';
import { isEmpty } from 'remeda';

import { useAnalytics } from './create-analytics';

export function usePageView(
  pageName: Analytics.Analytics.PageView['pageName'],
  pageData?: Analytics.Analytics.PageView['view'],
) {
  const analytics = useAnalytics();

  useEffect(() => {
    if (pageName && !isEmpty(pageName)) {
      analytics.track({
        type: Analytics.eventType.enum.PageView,
        data: {
          pageName,
          ...(isNotBlank(pageData) && { view: pageData }),
          window: {
            location: {
              href: window.location.href,
            },
          },
        },
      });
    }
    // This is intentional to ensure the effect only runs once per mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
